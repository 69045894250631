* {
  box-sizing: border-box;
}

body,
html,
#app {
  height: 100%;
  margin: 0;
  font-family: var(--ui-font);
}

:root {
  --ui-font: "Helvetica Neue", Helvetica, sans-serif;
}

.excalidraw.excalidraw {
  --color-primary: #fb9c04;
  --color-primary-darker: #e08b03;
  --color-primary-darkest: #d28203;
  --select-highlight-color: #6da5d5;
  --link-color: #6da5d5;
}

.excalidraw.excalidraw .color-picker-input {
  height: inherit;
}

.excalidraw .App-menu_top.App-menu_top {
  grid-template-columns: 1fr max-content 1fr;
}

.replay-controls.replay-controls {
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9000;
  background-color: var(--island-bg-color);
  box-shadow: var(--shadow-island);
  border-radius: 4px;
  display: flex;
  padding: 0.5rem;
  gap: 0.25rem;
}

.replay-controls__button {
  display: block;
  font-size: 24px;
  aspect-ratio: 1/1;
  width: 2.5rem;
}

svg.gauge .value {
  stroke-width: 10px;
  transition: stroke 0.1s ease;
}

svg.gauge .dial {
  stroke-width: 8px;
}

.replay-controls__progress {
  height: 40px;
  width: 40px;
}

.visually-hidden {
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.topright {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: max-content !important;
  width: max-content !important;
}

.topright__toggle {
  align-items: center;
  display: flex;
  justify-content: center;
}

.topright__icon {
  cursor: pointer;
  display: inline-block;
  height: 1.5rem;
  opacity: 0.5;
  transition: opacity 0.2s ease;
  width: 1.5rem;
}

.topright__icon:hover {
  opacity: 1;
}

.topright__toggle-input:not(:checked) ~ * {
  display: none;
}
